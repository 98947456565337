<i18n>
{
    "ru": {
        "gallery": "Галерея"
    }
}
</i18n>
<template>
    <div
        class="photo-gallery"
        :class="rootClasses"
        :data-count="slicedImages.length">

        <div v-for="(image, index) in images"
            :key="image.src || image.url"
            @click="showGallery(index)"
            class="photo-gallery__image">
            <img
                v-lazy-load :data-src="image.src || image.url"
                :title="image.title"
                :alt="image.alt"
                :data-scaling="image.scale" />
        </div>

        <button
            v-if="isMoreShow"
            @click="showGallery(4)"
            class="photo-gallery__more">
            {{ moreText }}
        </button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'PhotoGallery',
    props: {
        user: {
            type: Object,
            default: null
        },
        images: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            loaded: false,
            selectedSlide: 0
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop']),
        rootClasses() {
            return {
                'photo-gallery_loaded': this.loaded
            }
        },
        isMoreShow() {
            return this.images.length > 5
        },
        moreText() {
            const counter = this.images.length - this.slicedImages.length

            return `+${counter}`
        },
        slicedImages() {
            return this.images.slice(0, 5)
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.loaded = true
        })
    },
    methods: {
        showGallery(selectedSlide) {
            this.showPopup('gallery', {
                title: this.$t('gallery'),
                pictures: this.images,
                user: this.user,
                slideIndex: selectedSlide,
                size: 'big',
            }, {
                fullscreen: !this.isDeviceDesktop,
                swipeY: !this.isDeviceDesktop
            });
        }
    }
}
</script>

<style lang="less">
.photo-gallery {
    --row-big: rem(224);
    --row-regular: rem(112);
    --row-middle: rem(108);
    --row-small: rem(72);
    --row-single: rem(224);

    @{mobile} & {
        @media (orientation: landscape){
            --row-middle: rem(224);
            --row-regular: rem(224);
            --row-small: rem(112);
        }
    }

    display: grid;
    grid-auto-columns: 1fr;
    grid-gap: rem(9);

    margin: rem(24) 0;

    opacity: 0;

    transition: opacity .3s ease;

    @{desktop} & {
        --row-big: rem(485);
        --row-regular: rem(305);
        --row-middle: rem(350);
        --row-small: rem(236);
        --row-single: rem(410);

        grid-gap: rem(16);

        margin: rem(40) 0;
    }

    &_loaded {
        opacity: 1;
    }

    &[data-count="1"] {
        grid-template: 'a' var(--row-single)
    }

    &[data-count="2"] {
        grid-template: 'a b' var(--row-big)
    }

    &[data-count="3"] {
        grid-template:
            'a a' var(--row-big)
            'b c' var(--row-regular)
    }

    &[data-count="4"] {
        grid-template:
            'a a a' var(--row-big)
            'b c d' var(--row-small)
    }

    &[data-count="5"] {
        grid-template:
            'a a a b b b' var(--row-middle)
            'c c d d e e' var(--row-small)
    }

    &__image {
        cursor: pointer;
        overflow: hidden;
        position: relative;
        transition: opacity .2s ease;
        border-radius: rem(3);
        transform: translate3d(0,0,0);
        backface-visibility: hidden;

        &:nth-child(1) { grid-area: a }
        &:nth-child(2) { grid-area: b }
        &:nth-child(3) { grid-area: c }
        &:nth-child(4) { grid-area: d }
        &:nth-child(5) { grid-area: e }
        &:nth-child(n + 6) {
            display: none;
        }

        @{desktop} & {
            &:hover{
                opacity: .9;
            }
        }

        img{
            object-fit: cover;
            height: 100%;
            width: 100%;
            display: block;
            pointer-events: none;
        }
    }

    &__more {
        display: flex;
        grid-area: e;
        align-items: center;
        justify-content: center;

        background-color: rgba(0, 0, 0, .5);
        border: none;
        color: @color-white;
        font-size: rem(20);
        font-weight: 500;
        line-height: 140%;
        outline: none;
        text-align: center;

        padding: 0;

        position: relative;
        z-index: 1;

        transition: background-color .15s ease;

        .hover({
            background-color: rgba(0, 0, 0, .8);
        });
    }
}
</style>
