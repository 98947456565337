/* eslint-disable no-useless-constructor */
import Model from '@/models/Model'
import Author from '@/models/articles/Author'
import Tag from '@/models/articles/Tag'
import Like from '@/models/services/like'
import Article from '@/models/catalog/Article';
import News from '@/models/catalog/News';
import Page from '@/models/catalog/Page';
import Section from '@/models/articles/Section'
import Comment from '@/models/users/Comment'
import CommentRating from '@/models/users/CommentRating'

export default class ArticleModel extends Model {

    resource() {
        return 'articles'
    }

    primaryKey() {
        return 'code'
    }

    userLikes() {
        return this.hasMany(Like)
    }

    recommended() {
        return this.hasMany(Section, 'recommended')
    }

    rubrics() {
        return this.hasMany(Section)
    }

    bestAuthors() {
        return this.hasMany(Author, 'best-authors')
    }

    bestTags() {
        return this.hasMany(Tag, 'best-tags')
    }

    relatedNews() {
        return this.hasMany(News, 'related-news')
    }

    relatedPages() {
        return this.hasMany(Page, 'related-pages')
    }

    bestRelatedArticles() {
        return this.hasMany(Article, 'best-related-articles')
    }

    relatedArticles() {
        return this.hasMany(Article, 'related-articles')
    }

    commentsRatings() {
        return this.hasMany(CommentRating)
    }

    commentsList() {
        return this.hasMany(Comment)
    }

    likes() {
        return this.hasMany(Like)
    }
}
