import get from 'lodash/get';
import Like from '@/models/services/like'

export default {
    methods: {
        sharePage(objectData) {
            this.showPopup('share', {
                title: get(objectData, 'meta.title'),
                text: get(objectData, 'meta.description'),
                image: get(objectData, 'meta["og:image"]'),
            });
        },
        /**
         *
         * @param {boolean} value
         * @param {object} TargetApiModelConstructor - обязательно конструктор
         * @param {object, boolean} notices - Объект с параметрами для переводов уведомлений
         * @param {boolean} hideNotices - Флаг не показывать уведомления (только ворнинги)
         * @param {object} chainConstructor - Дополнительный конструктор для вложенных эндпоинтов (необязательный)
         * @returns {Promise<boolean>}
         */
        async onActionLike(value, TargetApiModelConstructor, notices, hideNotices = true, chainConstructor = null, returnTotal = false) {
            if(sessionStorage.getItem('likeProcess')){
                return
            }else{
                sessionStorage.setItem('likeProcess', true);
            }

            let isLiked = value;
            let like = new Like().for(TargetApiModelConstructor);

            if (chainConstructor) {
                like = new Like().for(TargetApiModelConstructor, chainConstructor);
            }

            if (isLiked) {
                try {
                    await like.save();
                    if (!hideNotices) {
                        this.$alert.success(get(notices,'onLiked', this.$t('alerts.is_liked')))
                    }
                } catch(e) {
                    const { code } = e.response.data

                    if (code === 'like-already-exists') {
                        if(!hideNotices) {
                            this.$alert.info(get(notices, 'onLikeAlready', this.$t('alerts.is_unliked')))
                        }
                        isLiked = true
                    } else {
                        this.$alert.error(get(!hideNotices, 'onError', this.$t('alerts.like_error')));
                    }
                }
            } else {
                try {
                    await like.delete();
                    if(!hideNotices) {
                        this.$alert.info(get(notices, 'onUnliked', this.$t('alerts.is_unliked')))
                    }
                } catch(e) {
                    this.$alert.error(get(notices, 'onError', this.$t('alerts.like_error')));
                }
            }

            sessionStorage.removeItem('likeProcess');

            if(returnTotal){
                return {
                    total: get(like, 'total', 0),
                    isLiked
                }
            }

            return isLiked;
        },
        heartIcon(isLiked, colors = false) {
            return isLiked ?
                'icon-heart-red' :
                ('icon-heart-' + (colors ? 'colors' : 'current'));
        },
        subscribeIcon(isSubscribe) {
            return isSubscribe ? 'icon-wifi-white' : 'icon-wifi-current'
        }
    }
}
