/* eslint-disable no-useless-constructor */
import Model from '@/models/Model'

import News from '@/models/catalog/News';
import Article from '@/models/catalog/Article';
import Page from '@/models/catalog/Page';
import Comment from '@/models/users/Comment';
import CommentRating from '@/models/users/CommentRating';
import Like from '@/models/services/like';
import Rating from '@/models/catalog/Rating';
import Property from '@/models/catalog/perfume/Property';

export default class Shop extends Model {
    resource() {
        return 'shops'
    }

    primaryKey() {
        return 'code'
    }

    userLikes() {
        return this.hasMany(Like)
    }

    userRatings() {
        return this.hasMany(Rating)
    }

    properties() {
        return this.hasMany(Property);
    }

    commentsList() {
        return this.hasMany(Comment)
    }

    commentsRatings() {
        return this.hasMany(CommentRating)
    }

    relatedNews() {
        return this.hasMany(News, 'related-content')
    }

    relatedArticles() {
        return this.hasMany(Article, 'related-articles')
    }

    relatedPages() {
        return this.hasMany(Page, 'related-pages')
    }

    patchShop(params) {
        return this.request({
            method: 'PATCH',
            url: this.endpoint(),
            data: params
        }).then(response => {
            if(response) {
                return response.data;
            }
        });
    }
}
