/* eslint-disable no-useless-constructor */
import Model from '@/models/Model'
import Article from '@/models/articles/'

export default class Section extends Model {

    resource() {
        return 'sections'
    }

    primaryKey() {
        return 'code'
    }

    relatedArticles()  {
        return this.hasMany(Article)
    }
}
