/* eslint-disable no-useless-constructor */
import Model from '@/models/Model'

export default class Author extends Model {

    resource() {
        return 'authors'
    }

    primaryKey() {
        return 'code'
    }

}
