<i18n>
    {
        "ru": {
            "gender-male": "Мужской",
            "at": "в",
            "gender-female": "Женский",
            "gender-unisex": "Унисекс",
            "edition": "Редакция Aromo",
            "go": "Перейти",
            "reply": "Ответить",
            "news_is_liked": "Новость добавлена в избранное",
            "news_liked_already": "Новость уже добавлена в избранное",
            "news_is_unliked": "Новость удалена из избранного",
            "is_liked": "Статья добавлена в избранное",
            "liked_already": "Статья уже добавлена в избранное",
            "is_unliked": "Статья удалена из избранного"
        }
    }
</i18n>
<template>
    <figure
        class="block-article"
        :class="rootClasses">

        <slot name="header"></slot>

        <div class="block-article__wrapper">
            <html-link
                class="block-article__link block-article__link-main"
                v-if="url && !isEqual(size, 'feed')"
                :url="url"
                :aria-label="title"/>

            <picture
                v-if="computedPicture"
                :style="{'background-image': preloadPlaceholder ? `url(${computedPicture})` : null}"
                class="block-article__picture"
                :class="{'pre-placeholder': preloadPlaceholder}">

                <img
                    v-lazy-load :data-src="computedPicture"
                    :alt="alt" />

                <html-link
                    class="block-article__link"
                    v-if="isEqual(size, 'feed') && url"
                    :url="url"
                    :aria-label="title"/>

            </picture>

            <header
                class="block-article__header"
                v-if="!withoutHeader && isRegular">

                <div class="block-article__name">

                    <html-link
                        v-if="userUrl"
                        class="block-article__link block-article__link_full"
                        :url="userUrl"
                        :aria-label="name"/>

                    <img
                        class="block-article__name-picture"
                        v-if="authorPhoto && get(subscriptions, 'entityType') === 'user'"
                        v-lazy-load :data-src="get(authorPhoto, 'url', getUserNoImage)"
                        :alt="alt" />
                    <!-- eslint-disable-next-line -->
                    <span v-html="name"></span>
                </div>
                <svg-icon
                    v-if="isEdition"
                    v-tooltip.top="tooltipOptions"
                    class="block-article__tooltip"
                    name="book"/>
                <div class="block-article__date">{{ getDate }}</div>
            </header>

            <div class="block-article__content">
                <div class="block-article__title" v-if="title">

                    <!-- eslint-disable-next-line -->
                    <html-link v-html="title"
                        class="block-article__title-link"
                        v-if="isEqual(size, 'feed') && url"
                        :url="url" />
                    <!-- eslint-disable-next-line -->
                    <span v-else v-html="title"></span>
                </div>

                <div class="block-article__name"
                    v-if="isWide">

                    <html-link
                        v-if="userUrl"
                        class="block-article__link block-article__link_full"
                        :url="userUrl"
                        :aria-label="name"/>

                    <!-- eslint-disable-next-line -->
                    <span v-html="name"></span>

                    <svg-icon
                        v-if="isEdition"
                        v-tooltip.top="tooltipOptions"
                        class="block-article__tooltip block-article__name-edition"
                        name="book-white"/>
                </div>

                <!-- eslint-disable-next-line -->
                <div v-html="desc"
                    v-if="isRegular"
                    :class="descriptionClasses"
                    class="block-article__description"></div>
            </div>

            <PhotoGallery
                class="block-article__gallery"
                v-if="pictures && pictures.length"
                :user="getGalleryUser"
                :images="pictures"/>

            <BaseVideo
                v-if="videos && videos.length"
                :url="get(videos, '[0].htmlUrl')"
                class="block-article__video"/>

            <footer
                class="block-article__footer"
                v-if="!withoutFooter && isRegular">
                <div
                    class="block-article__icon-text block-article__likes"
                    @click="onLike">
                    <svg-icon :name="heartIcon(liked)"/>
                    <span> {{ likesCount || 0 }} </span>
                </div>
                <div class="block-article__icon-text block-article__comments">
                    <html-link
                        class="block-article__link block-article__link_full"
                        v-if="url"
                        :url="url + '#comments'"
                        :aria-label="title"/>
                    <svg-icon name="icon-message-current"/>
                    <span> {{ commentsTotal || 0 }} </span>
                </div>

                <button class="block-article__reply-to"
                    v-if="isEqual(size, 'feed')"
                    @click.prevent="replyTo">{{ $t('reply') }}</button>

                <html-link class="block-article__go-to"
                    v-if="isEqual(size, 'feed')"
                    :url="url">{{ $t('go') }}</html-link>

                <div v-else class="block-article__icon-text block-article__views">
                    <span> {{ views || 0 }} </span>
                    <svg-icon name="icon-eye-gray"/>
                </div>

            </footer>
        </div>


    </figure>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get'
import includes from 'lodash/includes'
import uniqueId from 'lodash/uniqueId'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'

import PhotoGallery from '@/components/articles/PhotoGallery'

import OfferMixin from '@/mixins/catalog/offers.mixin'
import ButtonActionsMixin from '@/mixins/catalog/buttonActions'
import ModelMixin from '@/mixins/model.mixin'

export default {
    name: 'BlockArticle',
    components: {
        PhotoGallery
    },
    mixins: [ ModelMixin, OfferMixin, ButtonActionsMixin ],
    props: {
        entityType: {
            type: String,
            default: null
        },
        section: {
            type: Object,
            default: () => {}
        },
        withoutHeader: {
            type: Boolean,
            default: false
        },
        withoutFooter: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'regular',
            validator(value) {
                return includes(['wide', 'feed', 'regular', 'long'], value)
            }
        },
        source: {
            type: String,
            default: null
        },
        code: {
            type: String,
            default: null
        },
        userUrl: {
            type: String,
            default: null
        },
        url: {
            type: String,
            default: null
        },
        // pictures: {
        //     type: Array,
        //     default: () => []
        // },
        // videos: {
        //     type: Array,
        //     default: () => []
        // },
        views: {
            type: [ String, Number ],
            default: ''
        },
        likes: {
            type: [ String, Number ],
            default: ''
        },
        comments: {
            type: [ String, Number ],
            default: 0
        },
        authorPhoto: {
            type: [ String, Object ],
            default: null
        },
        smallDescription: {
            type: Boolean,
            default: true
        },
        subscriptions: {
            type: Object,
            default: null
        },
        alt: {
            type: String,
            default: null
        },
        preloadPlaceholder: {
            type: Boolean,
            default: false
        },
        pictureThumbs: {
            type: Object,
            default: () => {}
        },
        coverThumbs: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            liked: false,
            userRatings: null,
            addedCommentsCount: 0,
            id: null,
            isLikeProcess: false,
            pictures: [],
            videos: [],
            desc: this.description || null
        }
    },
    globalBus: {
        'fetch-complete'() {
            this.userRatings = this.getRatingsByCode(this.code, this.ratingsEntityType)
            this.liked = !!this.userRatings
        }
    },
    computed: {
        getGalleryUser() {
            if (this.size !== 'feed') return

            return {
                name: this.name,
                photo: this.authorPhoto
            }
        },
        isRegular() {
            return includes(['regular', 'feed', 'long'], this.size)
        },
        isWide() {
            return this.size === 'wide'
        },
        ...mapGetters(['getUserNoImage', 'getRatingsByCode']),
        descriptionClasses() {
            return {
                'block-article__description_small': this.smallDescription
            }
        },
        commentsTotal() {
            return this.comments + this.addedCommentsCount
        },
        rootClasses() {
            return {
                'block-article_wide': this.size === 'wide',
                'block-article_feed': this.size === 'feed',
                'block-article_post': this.entityType === 'post'

            }
        },
        isEdition() {
            return this.source === 'edition';
        },
        likesCount() {
            let likes = this.likes

            if (this.userRatings && likes) likes--
            if (this.liked) likes++

            return likes
        },
        likeEventName() {
            return 'user-like-article-' + this.id
        },
        page() {
            if (this.entityType) {
                return this.entityType
            }

            const isNews = includes(this.$route.path, '/news/')

            return isNews ? 'news' : 'articles'
        },
        replyToEventName() {
            return 'reply-to-article-' + this.code
        },
        commentToEventName() {
            return 'added-comment-to-article-' + this.code
        },
        tooltipOptions() {
            const desktopOptions = {
                content: this.$t('edition'),
                delay: { show: 300, hide: 100 },
                offset: 12,
                defaultPlacement: 'top'
            }
            return this.isDeviceDesktop ? desktopOptions : {}
        },
        ratingsEntityType(){
            return includes(this.url, '/news/') ? 'news' : 'article'
        },
        computedPicture(){
            if(this.pictureThumbs && this.coverThumbs){
                // Desktop
                switch(this.size){
                case 'wide': return get(this.coverThumbs, 'xl.url', this.coverThumbs.raw.url)
                case 'long': return get(this.coverThumbs, 'l.url', this.coverThumbs.raw.url)
                default: return get(this.pictureThumbs, 'm.url', this.pictureThumbs.raw.url)
                }
            }else if(this.coverThumbs){
                // Mobile
                return get(this.coverThumbs, 'm.url', this.coverThumbs.raw.url)
            }else{
                return this.picture
            }
        }
    },
    created(){
        if(this.entityType === 'post'){
            const content = this.desc.split('<span class="media-divider"></span>')

            if(content.length > 1){
                this.desc = content[0]
                this.replaceMedia(content[1])
            }

        }
    },
    beforeDestroy() {
        if (this.entityType) {
            if (this.$bus._events[this.replyToEventName])
                this.$bus._events[this.replyToEventName] = []

            if (this.$bus._events[this.commentToEventName])
                this.$bus._events[this.commentToEventName] = []
        }
    },
    mounted() {
        this.id = 'article_' + uniqueId()

        this.userRatings = this.getRatingsByCode(this.code, this.ratingsEntityType)
        this.liked = !!this.userRatings

        // eslint-disable-next-line
        if (this.entityType && process.client) {
            this.$bus.$on(this.replyToEventName, callback => {
                callback()
            })

            this.$bus.$on(this.commentToEventName, comment => {
                this.addedCommentsCount++
            })
        }

        // eslint-disable-next-line
        if (process.client) {
            this.$bus.$on(this.likeEventName, async value => {
                const model = this.getInstanseByPage(this.page, this.code)
                if (!model) return

                let notices = {}

                if (this.page === 'news') {
                    notices = {
                        'onLiked': this.$t("news_is_liked"),
                        'onUnliked': this.$t("news_is_unliked"),
                        'onLikeAlready': this.$t("news_liked_already"),
                    };
                } else {
                    notices = {
                        'onLiked': this.$t("is_liked"),
                        'onUnliked': this.$t("is_unliked"),
                        'onLikeAlready': this.$t("liked_already"),
                    };
                }

                this.liked = await this.onActionLike(
                    value,
                    model,
                    notices
                )

                this.isLikeProcess = false;
            })
        }
    },
    methods: {
        get,
        isEqual,
        ...mapGetters(['']),
        onLike() {
            if (!this.code) return
            if(!this.isLikeProcess){
                this.isLikeProcess = true;
                this.$authEmit(this.likeEventName, !this.liked)
            }
        },
        replyTo() {
            this.$authEmit(this.replyToEventName, () => {
                this.showPopup('popup-comment', {
                    page: this.page,
                    code: this.code,
                    addEvent: this.commentToEventName
                })
            })
        },
        replaceMedia(content){
            const patterns = {
                image: /<img [^>]*src="[^"]*"[^>]*>/gm,
                iframe: /<iframe [^>]*src="[^"]*"[^>]*>/gm,
                alt: /.*alt="([^"]*)".*/,
                src: /.*src="([^"]*)".*/,
                dsrc: /.*data-src="([^"]*)".*/,
                width: /.*width="([^"]*)".*/,
                height: /.*height="([^"]*)".*/,
                scale: /.*data-scaling="([^"]*)".*/,
            }

            const rawImages = content.match(patterns.image)

            if (rawImages && rawImages.length) {
                this.pictures = map(rawImages, x => {
                    const src = get(x.match(patterns.src), '[1]') || get(x.match(patterns.dsrc))
                    const alt = escape(get(x.match(patterns.alt), '[1]'))
                    const width = get(x.match(patterns.width), '[1]')
                    const height = get(x.match(patterns.height), '[1]')
                    const scale = get(x.match(patterns.scale), '[1]')

                    const image = {}

                    if (src) {
                        image.src = src
                        image.url = src
                        image.width = width
                        image.height = height
                        image.scale = scale
                    }

                    image.alt = image.title = alt || ''

                    image.index = uniqueId('gallery_editor_')

                    if (image.src) return image
                })
            }

            const rawVideo = content.match(patterns.iframe)

            if (rawVideo && rawVideo.length) {
                const videoObj = map(rawVideo, x => {
                    const src = get(x.match(patterns.src), '[1]') || get(x.match(patterns.dsrc), '[1]')

                    const video = {}

                    if(src){
                        video.src = src
                        video.htmlUrl = src
                    }

                    video.index = uniqueId('gallery_editor_')

                    if (video.src) return video
                })

                this.videos = [videoObj[0]]
            }
        }
    }
};
</script>


<style lang="less">
.block-article {
    margin-bottom: 0;
    padding-top: rem(19);
    padding-bottom: rem(24);
    overflow: hidden;

    .page-padding();

    @{desktop} & {
        border: rem(1) solid @color-gray-white;
        border-radius: rem(3);

        padding-top: 0;
        padding-bottom: rem(20);

        transition: box-shadow 0.2s ease;

        &:hover,
        &:focus {
            @media @hover {
                box-shadow: 0 rem(1) rem(8) rgba(0, 0, 0, 0.1);
            }
        }
    }

    &__wrapper {
        min-height: 100%;
        height: 100%;

        position: relative;

        overflow: hidden;

        @{desktop} & {
            display: flex;
            flex-direction: column;
        }
    }

    &__link {
        display: block;
        cursor: pointer;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        opacity: .5;

        transition: background-color .15s ease;

        &_full {
            bottom: 0;
        }
    }

    &__link-main:hover ~ &__content &__title {
        @media @hover {
            color: @color-primary;
        }
    }

    &__picture {
        display: block;

        height: rem(188);
        width: 100%;

        position: relative;

        img {
            display: block;
            object-fit: cover;
            border-radius: rem(3);

            height: 100%;
            width: 100%;
        }

        @{desktop} & {
            height: rem(228);
        }
    }

    &__content {
        @{desktop} & {
            .page-padding();
        }
    }

    &__header {
        display: flex;
        align-items: center;

        font-size: rem(13);
        line-height: 1.2;

        padding-top: rem(17);
        padding-bottom: rem(9);

        @{desktop} & {
            .page-padding();
        }
    }

    &__picture + &__content {
        padding-top: rem(16);
    }

    &__name {
        display: flex;
        align-items: center;

        font-size: rem(12);
        font-weight: 500;
        white-space: nowrap;

        margin-right: rem(4);

        position: relative;

        transition: color .05s ease;

        min-width: 0;

        @media @min375 {
            font-size: rem(13);
        }

        span {
            display: block;

            text-overflow: ellipsis;

            max-width: 50vw;

            overflow: hidden;
        }

        &-edition{
            margin-left: rem(5);
        }

        &-picture {
            border-radius: 50%;

            height: rem(20);
            width: rem(20);
        }

        .hover({
            color: @color-primary;
        });
    }

    &__tooltip {
        display: inline-block;

        margin-right: rem(8);

        height: rem(16);
        width: rem(16);

        position: relative;
        top: rem(-1);
        left: rem(2);
        z-index: 2;
    }

    &__date {
        // color: @color-gray-light;
        color: @color-gray-contrast;
        font-size: rem(12);
        white-space: nowrap;

        margin-left: auto;
        padding-top: rem(2);

        @media @min375 {
            font-size: rem(13);
        }
    }

    &__title {
        font-size: rem(20);
        font-weight: 500;
        line-height: rem(26);

        position: relative;

        transition: color .05s ease;

        &-link {
            color: inherit;

            transition: inherit;

            .hover({
                color: @color-primary;
            });
        }
    }


    &__description {
        font-size: rem(15);
        line-height: 150%;
        text-overflow: ellipsis;
        margin-top: rem(10);
        overflow: hidden;

        &_small {
            max-height: rem(46);
            .text-ellipsis(2);

            @{desktop} & {
                max-height: rem(69);
            }
        }

        a {
            color: @color-primary;

            .hover({
                text-decoration: underline;
            });
        }

        &>p{
            &:empty{
                display: none;
            }
        }

        figure,
        .photo-gallery{
            display: none;
        }

        iframe{
            visibility: hidden;
            width: 100%;
            display: block;
            height: rem(348);

            @{mobile} & {
                height: rem(189);
            }
        }
    }

    &__icon-text {
        cursor: pointer;

        // color: @color-gray-light;
        color: @color-gray-contrast;
        font-size: rem(13);
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;

        position: relative;
        z-index: 2;

        overflow: hidden;

        transition: color .15s ease;

        svg {
            height: rem(20);
            width: rem(20);
            color: inherit;
        }

        svg ~ span {
            display: inline-block;

            margin-left: rem(4);

            min-width: rem(14);
        }

        span ~ svg {
            margin-left: rem(2);
        }

        span {
            position: relative;
            top: rem(1);
        }
    }

    &__likes {
        margin-right: rem(22);
    }

    &__comments {
        margin-right: auto;
    }

    &__likes,
    &__comments {
        cursor: pointer;

        .hover({
            color: @color-gray;
        });
    }

    &__views {
        cursor: default;
    }

    &__footer {
        display: flex;
        justify-content: space-between;

        padding-top: rem(16);

        user-select: none;

        @{desktop} & {
            .page-padding();

            margin-top: auto;
        }
    }

    &_feed {
        padding-top: 0;

        @{desktop} & {
            border: none;
            box-shadow: none;

            .hover({
                box-shadow: none;
            });
        }

    }

    &.block-article_post.block-article_feed{
        .block-article{
            &__description{
                margin-top: 0;

                p{
                    margin-bottom: rem(16);

                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }

            &__gallery{
                margin-bottom: 0;
                margin-top: rem(16);
            }
        }
    }

    &__go-to,
    &__reply-to {
        background: none;
        border: none;
        color: @color-gray-light;

        padding: 0;

        transition: color .15s ease;

        .hover({
            color: @color-primary;
        });
    }

    &__reply-to {
        margin-left: auto;
    }

    &__go-to {
        margin-left: rem(20);
    }

    &__header,
    &__content,
    &__footer {
        @{desktop} .block-article_feed & {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &__gallery {
        @{mobile} &,
        @{desktop} & {
            margin: 0;
            margin-bottom: rem(16);
        }

        @{desktop} & {
            --row-big: rem(276);
            --row-regular: rem(173);
            --row-middle: rem(199);
            --row-small: rem(134);
            --row-single: rem(410);
        }
    }

    &__video{
        margin-top: rem(16);
    }

    @{desktop} &_wide {
        padding-bottom: 0;
    }

    @{desktop} &_wide &__picture {
        height: 100%;
        max-height: rem(485);
    }

    @{desktop} &_wide &__title {
        color: @color-white !important;
        font-size: rem(24);
        margin-bottom: rem(8);
    }


    @{desktop} &_wide &__name {
        font-size: rem(15);
        font-weight: 500;

        pointer-events: all;

        .hover({
            color: @color-white;
        });
    }

    @{desktop} &_wide &__content {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, black 100%);
        color: @color-white;

        padding-top: rem(48);
        padding-right: rem(32);
        padding-bottom: rem(32);
        padding-left: rem(32);

        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
    }

    @{desktop} &_wide:hover &__wrapper > &__link {
        @media @hover {
            background-color: @color-primary;
        }
    }

    @{desktop} &_wide &__wrapper > &__content {
        z-index: 2;

        pointer-events: none;
    }
}
</style>

