import Perfume from '@/models/catalog/perfume'
import Brand from '@/models/catalog/brand'
import Shop from '@/models/catalog/Shop'
import Article from '@/models/articles/'
import News from '@/models/news/'
import Post from "@/models/Post";

export default {
    computed: {
        shopCode() {
            return this.$route.params.code
        },
        brandCode() {
            return this.$route.params.code
        },
        detailCode() {
            return this.$route.params.code
        },
    },
    methods: {

        getInstanseByPage(page, code = null) {
            let model = null
            switch(page) {
            case 'perfume':
                model = new Perfume({code: code || this.detailCode})
                break;
            case 'brand':
                model = new Brand({code: code || this.brandCode})
                break;
            case 'shop':
                model = new Shop({code: code || this.shopCode})
                break;
            case 'articles':
                model = new Article({code: code || this.detailCode})
                break;
            case 'article':
                model = new Article({code: code || this.detailCode})
                break;
            case 'news':
                model = new News({code: code || this.detailCode})
                break;
            case 'post':
                model = new Post({code: code || this.detailCode})
                break;
            case 'default':
                break;
            }

            return model
        },
    },
}
